.wrapper {
  padding: 20px 40px;
  & > div {
    display: flex;
    & > div {
      margin-right: 15px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .actions {
    padding-right: 0;
  }
}
